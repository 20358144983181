<template>
  <div class="pad-home">
    <HeaderBar title="个人信息" :showLeft="true"></HeaderBar>
    <div class="qrcode-container">
      <div class="qrcode-bg">

      </div>
      <div class="qrcode-pop">
        <div class="qrcode-pop-title">
          分享扫码有惊喜
        </div>
        <div class="qrcode-pop-txt">
          扫码领奖
        </div>
        <div ref="qrcode" style="display: none;"
             class="qrcode-pop-code">
             </div>
        <div class="qrcode-logo-vertical">
          <!-- <img src="../../assets/logo_vertical@2x.png" alt=""> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from '@/components/headerbar/headerbar'
import QRCode from 'qrcodejs2'
import { getStore } from '../../lib/storage';
import $ from 'jquery'
import wechat from '../../lib/wechat';
import config from '../../service/httpConfig'

export default {
  name: 'qrcode',
  components:{HeaderBar},
  computed: {
    link () {
      return `${window.location.href.split('#')[0]}#/invitelogin/?userTel=${JSON.parse(getStore('userInfo')).Tel}&&userAreaCode=${JSON.parse(getStore('userInfo')).AreaCode.substr(1,2)}`
    }
  },
  methods: {
    createQRcode () {
      new QRCode(this.$refs.qrcode, {
        width: 200,
        height: 200,
        text: this.link,
        // clolor: '#0CAA32',
        // colorDark: "#fff",
        // colorLight: "#0CAA32",
        correctLevel: QRCode.CorrectLevel.H,
        render: 'canvas'
      });
      // alert(this.link)
      var canvas=document.getElementsByTagName('canvas')[0];
      var img =this.convertCanvasToImage(canvas);
        $('.qrcode-logo-vertical').append(img);
    },
   convertCanvasToImage(canvas) {  
     //新建Image对象
     var image = new Image();  
     // canvas.toDataURL 返回的是一串Base64编码的URL
     image.src = canvas.toDataURL("image/png");  
     return image;  
   },
    wxRegCallback () {
      this.wxShareTimeline()
      this.wxShareAppMessage()
    },
    wxShareAppMessage () {
      // let Tel = JSON.parse(getStore('userInfo')).Tel
      let option = {
        title: '你的好友邀请你注册浣熊出行', // 分享标题, 请自行替换
        desc: `你的好友送给你代金券,赶紧去领`, // 分享描述, 请自行替换
        link:`${window.location.href.split('#')[0]}#/invitelogin/?userTel=${JSON.parse(getStore('userInfo')).Tel}&&userAreaCode=${JSON.parse(getStore('userInfo')).AreaCode.substr(1,2)}`, // 分享链接，根据自身项目决定是否需要split
        imgUrl: `${config.baseURL}/2.jpg`, // 分享图标, 请自行替换，需要绝对路径
        success: () => {
          alert('分享成功')
        },
        error: () => {
          alert('已取消分享')
        }
      }
      // 将配置注入通用方法
      wechat.ShareAppMessage(option)
    },  
     // 分享朋友圈
    wxShareTimeline () {
      let option = {
        title: '浣熊分享人领优惠券活动', // 分享标题, 请自行替换
        link:`${window.location.href.split('#')[0]}#/invitelogin/?userTel=${JSON.parse(getStore('userInfo')).Tel}&&userAreaCode=${JSON.parse(getStore('userInfo')).AreaCode.substr(1,2)}`, // 分享链接，根据自身项目决定是否需要split
        imgUrl: `${config.baseURL}/2.jpg`, // 分享图标, 请自行替换，需要绝对路径
        success: () => {
          alert('分享成功')
        },
        error: () => {
          alert('已取消分享')
        }
      }
      // console.log(option)
      // 将配置注入通用方法
      wechat.ShareTimeline(option)
    },  
  },
  mounted () {
    this.$nextTick(() => {
      this.createQRcode()
    })
    let url = (window.location.href).replace(window.location.search, '')
    // alert(url)
    wechat.wxRegister(url, this.wxRegCallback)
  },
  
}
</script>

<style lang="less" scoped>
@import '../../styles/mixin.less';

.pad-home{
  padding-top: 94px;
}
.qrcode {
  &-container {
    // .size(100vw, 100vh);
    position: relative;
    min-height: 1238px;
    // left: 0;
    // right: 0;
    // top: 0;
    // bottom: 0;
    background: linear-gradient(
      360deg,
      rgba(57, 72, 243, 1) 0%,
      rgba(212, 231, 255, 1) 100%
    );
    // background-size: 100vw 100vh;
  }

  &-bg {
    .size(746px, 650px);
    margin: 0 auto;
    padding-top: 12px;
    background: url('../../assets/qrcode_bg.png') 0 12px no-repeat;
    background-size: 746px 650px;
  }

  &-pop {
    width: 630px;
    height: 650px;
    position: absolute;
    left: 60px;
    top: 500px;
    right: 60px;
    margin: 0 auto;
    background: rgba(245, 245, 245, 1);
    border-radius: 20px;

    &-title {
      margin: 30px auto 10px;
      font-size: 52px;
      font-family: 'PingFangSC-Medium';
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      line-height: 74px;
      text-align: center;
    }

    &-txt {
      margin-bottom: 30px;
      font-size: 30px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: rgba(137, 167, 253, 1);
      line-height: 42px;
      text-align: center;
    }

    &-code {
      margin: 0 auto;
      text-align: center;
      height: 200PX; /*no*/
      width: 200PX; /*no*/

      img {
        margin: 0 auto !important;
      }
    }
  }

  &-logo {
    &-vertical {
      .size(198px, 64px);
      margin: 30px auto;
      background: url('../../assets/logo_vertical@2x.png') 0 0 no-repeat;
      background-size: 198px 64px;
    }
  }
}
.qrcode-logo-vertical{
  margin: 0 auto;
      text-align: center;
      height: 200PX; /*no*/
      width: 200PX; /*no*/
        img {
        margin: 0 auto !important;
      }
} 
</style>

